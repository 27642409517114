<template>
  <div>
    <div class="row"  v-if="user.role.includes('cxo_user')">
      <div class="col-md-12">
        <div class="card">
          <div class="py-3 px-4">
            <h4>Hello</h4>
          </div>
        </div>
      </div>
    </div>
    <div class="row"  v-if="user.role.includes('hub_in_charge')">
      <div class="col-md-12" v-if="hub_notices.length > 0">
        <div class="card">
          <div class="py-3 px-4">
            <div v-for="(hub_notice, index) in hub_notices" :key="index" role="alert" aria-live="polite" aria-atomic="true" class="alert-dismissible blink" :class="hub_notice.type === 2 ? 'alert alert-danger' : 'alert alert-success'">
              <button type="button" title="Mark as read" aria-label="Close" class="close" @click="markAsRead(hub_notice.id)">×</button>
              <span class="font-weight-bold" style="color: #eee">{{ hub_notice.description }}</span><br/>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row" v-if="user.role.includes('admin_scenario_admin')">
      <div class="col-md-12">
        <div class="card">
          <div class="py-3 px-4">
            <router-link :to="{ name: 'adminIssueDashboard' }" class="btn btn-sm btn-success mr-2" tag="a">
              <i class="fe fe-eye"/> Go to admin scenario dashboard
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <div class="row" v-if="user.role.includes('staff_leave_application_approver')">
      <div class="col-md-12">
        <div class="card">
          <div class="py-3 px-4">
            <router-link :to="{ name: 'adminStaffApplicationList' }" class="btn btn-sm btn-success mr-2" tag="a">
              <i class="fe fe-eye"/> Go to staff leave application list
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <div class="row" v-if="user.role.includes('admin_scenario_user')">
      <div class="col-md-12">
        <div class="card">
          <div class="py-3 px-4">
            <router-link :to="{ name: 'admin-issue-accessory-dashboard' }" class="btn btn-sm btn-success mr-2" tag="a">
              <i class="fe fe-eye"/> Go to admin scenario dashboard
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <div class="row" v-if="user.role.includes('admin_scenario_department')">
      <div class="col-md-12">
        <div class="card">
          <div class="py-3 px-4">
            <router-link :to="{ name: 'hub-overall-issue' }" class="btn btn-sm btn-success mr-2" tag="a">
              <i class="fe fe-eye"/> Go to raises issue menu
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-4 col-md-12 custom-row-div" v-if="!user.role.includes('hub_in_charge') && !user.role.includes('admin_scenario_admin') && !user.role.includes('admin_scenario_user') && !user.role.includes('cxo_user')&& !user.role.includes('admin_scenario_department')">
        <div class="card">
          <div class="py-3 px-4">
            <div class="d-flex flex-wrap-reverse align-items-center pb-3">
              <div class="mr-auto">
                <div class="text-uppercase font-weight-bold font-size-24 text-dark">
                  <a-spin :spinning="spinning" :delay="delayTime">
                    {{ total_purchase_request_count }}
                  </a-spin>
                </div>
                <div class="font-size-18">
                  Total Purchase Request
                </div>
              </div>
              <div class="flex-shrink-0 font-size-36 text-gray-4 pl-1">
                <i class="fa fa-first-order" style="color: #17a2b8"  />
              </div>
            </div>
            <div class="font-italic font-size-14 text-center border-top pt-3">
              <!-- More Info-->
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-12 custom-row-div" v-if="!user.role.includes('hub_in_charge') && !user.role.includes('admin_scenario_admin') && !user.role.includes('admin_scenario_user') && !user.role.includes('cxo_user') && !user.role.includes('admin_scenario_department')">
        <div class="card">
          <div class="py-3 px-4">
            <div class="d-flex flex-wrap-reverse align-items-center pb-3">
              <div class="mr-auto">
                <div class="text-uppercase font-weight-bold font-size-24 text-dark">
                  <a-spin :spinning="spinning" :delay="delayTime">
                    ৳ {{ currencyConverter(total_product_value) }}
                  </a-spin>
                </div>
                <div class="font-size-18">
                  Total PR Value
                </div>
              </div>
              <div class="flex-shrink-0 font-size-36 text-gray-4 pl-1">
                <i class="fe fe-bar-chart" style="color: #A52A2A"  />
              </div>
            </div>
            <div class="font-italic font-size-14 text-center border-top pt-3"></div>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-12 custom-row-div" v-if="user.role.includes('hub_in_charge')">
        <div class="card">
          <div class="py-3 px-4">
            <div class="d-flex flex-wrap-reverse align-items-center pb-3">
              <div class="mr-auto">
                <div class="text-uppercase font-weight-bold font-size-24 text-dark">
                  <a-spin :spinning="spinning" :delay="delayTime">
                    {{ total_product }}
                  </a-spin>
                </div>
                <div class="font-size-18">
                  Total Items Stock
                </div>
              </div>
              <div class="flex-shrink-0 font-size-36 text-gray-4 pl-1">
                <i class="fe fe-shopping-bag" style="color: #FF00FF"  />
              </div>
            </div>
            <div class="font-italic font-size-14 text-center border-top pt-3"></div>
          </div>
        </div>
      </div>
      <!--Requisition Section-->
      <div class="col-lg-4 col-md-12 custom-row-div" v-if="user.role.includes('software_admin') || user.role.includes('head_of_admin') || user.role.includes('store_in_charge') || user.role.includes('hq') || user.role.includes('top_management')">
        <div class="card">
          <div class="py-3 px-4">
            <div class="d-flex flex-wrap-reverse align-items-center pb-3">
              <div class="mr-auto">
                <div class="text-uppercase font-weight-bold font-size-24 text-dark">
                  <a-spin :spinning="spinning" :delay="delayTime">
                    ৳ {{ currencyConverter(total_current_stock_value) }}
                  </a-spin>
                </div>
                <div class="font-size-18">
                  Total  <br>
                  Current Stock Value
                </div>
              </div>
              <div class="flex-shrink-0 font-size-36 text-gray-4 pl-1">
                <i class="fe fe-bar-chart" style="color: #A52A2A"  />
              </div>
            </div>
            <div class="font-italic font-size-14 text-center border-top pt-3"></div>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-12 custom-row-div" v-if="user.role.includes('software_admin') || user.role.includes('head_of_admin') || user.role.includes('store_in_charge') || user.role.includes('hq') || user.role.includes('top_management')">
        <div class="card">
          <div class="py-3 px-4">
            <div class="d-flex flex-wrap-reverse align-items-center pb-3">
              <div class="mr-auto">
                <div class="text-uppercase font-weight-bold font-size-24 text-dark">
                  <a-spin :spinning="spinning" :delay="delayTime">
                    {{ total_product_requisition }}
                  </a-spin>
                </div>
                <div class="font-size-18">
                  Total <br> Product Requisition
                </div>
              </div>
              <div class="flex-shrink-0 font-size-36 text-gray-4 pl-1">
                <i class="fa fa-shopping-cart" style="color: #007bff" />
              </div>
            </div>
            <div class="font-italic font-size-14 text-center border-top pt-3"></div>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-12 custom-row-div" v-if="user.role.includes('software_admin') || user.role.includes('head_of_admin') || user.role.includes('store_in_charge') || user.role.includes('hq') || user.role.includes('top_management')">
        <div class="card">
          <div class="py-3 px-4">
            <div class="d-flex flex-wrap-reverse align-items-center pb-3">
              <div class="mr-auto">
                <div class="text-uppercase font-weight-bold font-size-24 text-dark">
                  <a-spin :spinning="spinning" :delay="delayTime">
                    {{ total_admin_approved_product_requisition }}
                  </a-spin>
                </div>
                <div class="font-size-18">
                  Total Admin Approved <br> Product Requisition
                </div>
              </div>
              <div class="flex-shrink-0 font-size-36 text-gray-4 pl-1">
                <i class="fa fa-check" style="color: #28a745" />
              </div>
            </div>
            <div class="font-italic font-size-14 text-center border-top pt-3"></div>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-12 custom-row-div" v-if="user.role.includes('software_admin') || user.role.includes('head_of_admin') || user.role.includes('store_in_charge') || user.role.includes('hq') || user.role.includes('top_management')">
        <div class="card">
          <div class="py-3 px-4">
            <div class="d-flex flex-wrap-reverse align-items-center pb-3">
              <div class="mr-auto">
                <div class="text-uppercase font-weight-bold font-size-24 text-dark">
                  <router-link :to="{ name: 'requisitionAdminPendingApprovedList' }" tag="a">
                    <a-spin :spinning="spinning" :delay="delayTime">
                      {{ total_admin_pending_product_requisition }}
                    </a-spin>
                  </router-link>
                </div>
                <div class="font-size-18">
                  Total Admin Pending <br> Product Requisition
                </div>
              </div>
              <div class="flex-shrink-0 font-size-36 text-gray-4 pl-1">
                <router-link :to="{ name: 'requisitionAdminPendingApprovedList' }" tag="a">
                  <i class="fa fa-circle-o-notch fa-spin" style="color: #ffc107"/>
                </router-link>
              </div>
            </div>
            <div class="font-italic font-size-14 text-center border-top pt-3"></div>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-12 custom-row-div" v-if="user.role.includes('software_admin') || user.role.includes('head_of_admin') || user.role.includes('store_in_charge') || user.role.includes('hq') || user.role.includes('top_management')">
        <div class="card">
          <div class="py-3 px-4">
            <div class="d-flex flex-wrap-reverse align-items-center pb-3">
              <div class="mr-auto">
                <div class="text-uppercase font-weight-bold font-size-24 text-dark">
                  <a-spin :spinning="spinning" :delay="delayTime">
                    {{ total_warehouse_approved_product_requisition }}
                  </a-spin>
                </div>
                <div class="font-size-18">
                  Total Store Approved <br> Product Requisition
                </div>
              </div>
              <div class="flex-shrink-0 font-size-36 text-gray-4 pl-1">
                <i class="fa fa-check" style="color: #28a745"  />
              </div>
            </div>
            <div class="font-italic font-size-14 text-center border-top pt-3"></div>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-12 custom-row-div" v-if="user.role.includes('software_admin') || user.role.includes('head_of_admin') || user.role.includes('store_in_charge') || user.role.includes('hq') || user.role.includes('top_management')">
        <div class="card">
          <div class="py-3 px-4">
            <div class="d-flex flex-wrap-reverse align-items-center pb-3">
              <div class="mr-auto">
                <div class="text-uppercase font-weight-bold font-size-24 text-dark">
                  <router-link :to="{ name: 'requisitionWarehousePendingApprovedList' }" tag="a">
                    <a-spin :spinning="spinning" :delay="delayTime">
                      {{ total_warehouse_pending_product_requisition }}
                    </a-spin>
                  </router-link>
                </div>
                <div class="font-size-18">
                  Total Store Pending <br> Product Requisition
                </div>
              </div>
              <div class="flex-shrink-0 font-size-36 text-gray-4 pl-1">
                <router-link :to="{ name: 'requisitionWarehousePendingApprovedList' }" tag="a">
                  <i class="fa fa-circle-o-notch fa-spin" style="color: #ffc107"/>
                </router-link>
              </div>
            </div>
            <div class="font-italic font-size-14 text-center border-top pt-3"></div>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-12 custom-row-div" v-if="user.role.includes('software_admin') || user.role.includes('head_of_admin') || user.role.includes('store_in_charge') || user.role.includes('hq') || user.role.includes('top_management')">
        <div class="card">
          <div class="py-3 px-4">
            <div class="d-flex flex-wrap-reverse align-items-center pb-3">
              <div class="mr-auto">
                <div class="text-uppercase font-weight-bold font-size-24 text-dark">
                  <a-spin :spinning="spinning" :delay="delayTime">
                    {{ total_warehouse_reject_product_requisition }}
                  </a-spin>
                </div>
                <div class="font-size-18">
                  Total Store Reject <br> Product Requisition
                </div>
              </div>
              <div class="flex-shrink-0 font-size-36 text-gray-4 pl-1">
                <i class="fa fa-close" style="color: #dc3545" />
              </div>
            </div>
            <div class="font-italic font-size-14 text-center border-top pt-3"></div>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-12 custom-row-div" v-if="user.role.includes('software_admin') || user.role.includes('head_of_admin') || user.role.includes('store_in_charge') || user.role.includes('hq') || user.role.includes('top_management')">
        <div class="card">
          <div class="py-3 px-4">
            <div class="d-flex flex-wrap-reverse align-items-center pb-3">
              <div class="mr-auto">
                <div class="text-uppercase font-weight-bold font-size-24 text-dark">
                  <router-link :to="{ name: 'requisitionWarehousePendingTransferList' }" tag="a">
                    <a-spin :spinning="spinning" :delay="delayTime">
                      {{ total_warehouse_transfer_pending_product_requisition }}
                    </a-spin>
                  </router-link>
                </div>
                <div class="font-size-18">
                  Total Store Pending Transfer <br> Product Requisition
                </div>
              </div>
              <div class="flex-shrink-0 font-size-36 text-gray-4 pl-1">
                <router-link :to="{ name: 'requisitionWarehousePendingTransferList' }" tag="a">
                  <i class="fa fa-circle-o-notch fa-spin" style="color: #ffc107"/>
                </router-link>
              </div>
            </div>
            <div class="font-italic font-size-14 text-center border-top pt-3"></div>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-12 custom-row-div" v-if="user.role.includes('software_admin') || user.role.includes('head_of_admin') || user.role.includes('store_in_charge') || user.role.includes('hq') || user.role.includes('top_management')">
        <div class="card">
          <div class="py-3 px-4">
            <div class="d-flex flex-wrap-reverse align-items-center pb-3">
              <div class="mr-auto">
                <div class="text-uppercase font-weight-bold font-size-24 text-dark">
                  <router-link :to="{ name: 'requisitionTejgaonPendingDeliveryList' }" tag="a">
                    <a-spin :spinning="spinning" :delay="delayTime">
                      {{ total_tejgaon_pending_product_requisition }}
                    </a-spin>
                  </router-link>
                </div>
                <div class="font-size-18">
                  Total Tejgaon Pending Delivery<br> Product Requisition
                </div>
              </div>
              <div class="flex-shrink-0 font-size-36 text-gray-4 pl-1">
                <router-link :to="{ name: 'requisitionTejgaonPendingDeliveryList' }" tag="a">
                  <i class="fa fa-circle-o-notch fa-spin" style="color: #ffc107"/>
                </router-link>
              </div>
            </div>
            <div class="font-italic font-size-14 text-center border-top pt-3"></div>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-12 custom-row-div" v-if="user.role.includes('software_admin') || user.role.includes('head_of_admin') || user.role.includes('store_in_charge') || user.role.includes('hq') || user.role.includes('top_management')">
        <div class="card">
          <div class="py-3 px-4">
            <div class="d-flex flex-wrap-reverse align-items-center pb-3">
              <div class="mr-auto">
                <div class="text-uppercase font-weight-bold font-size-24 text-dark">
                  <a-spin :spinning="spinning" :delay="delayTime">
                    {{ total_delivery_product_requisition }}
                  </a-spin>
                </div>
                <div class="font-size-18">
                  Total Delivery <br> Product Requisition
                </div>
              </div>
              <div class="flex-shrink-0 font-size-36 text-gray-4 pl-1">
                <i class="fa fa-truck" style="color: #17a2b8" />
              </div>
            </div>
            <div class="font-italic font-size-14 text-center border-top pt-3"></div>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-12 custom-row-div" v-if="user.role.includes('software_admin') || user.role.includes('head_of_admin') || user.role.includes('store_in_charge') || user.role.includes('hq') || user.role.includes('top_management')">
        <div class="card">
          <div class="py-3 px-4">
            <div class="d-flex flex-wrap-reverse align-items-center pb-3">
              <div class="mr-auto">
                <div class="text-uppercase font-weight-bold font-size-24 text-dark">
                  <a-spin :spinning="spinning" :delay="delayTime">
                    {{ total_hub_accepted_product_requisition }}
                  </a-spin>
                </div>
                <div class="font-size-18">
                  Total Hub Accepted Delivery<br> Product Requisition
                </div>
              </div>
              <div class="flex-shrink-0 font-size-36 text-gray-4 pl-1">
                <i class="fa fa-check" style="color: #28a745" />
              </div>
            </div>
            <div class="font-italic font-size-14 text-center border-top pt-3"></div>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-12 custom-row-div" v-if="user.role.includes('software_admin') || user.role.includes('head_of_admin') || user.role.includes('store_in_charge') || user.role.includes('hq') || user.role.includes('top_management')">
        <div class="card">
          <div class="py-3 px-4">
            <div class="d-flex flex-wrap-reverse align-items-center pb-3">
              <div class="mr-auto">
                <div class="text-uppercase font-weight-bold font-size-24 text-dark">
                  <router-link :to="{ name: 'requisitionHubPendingDeliveryList' }" tag="a">
                    <a-spin :spinning="spinning" :delay="delayTime">
                      {{ total_hub_pending_product_requisition }}
                    </a-spin>
                  </router-link>
                </div>
                <div class="font-size-18">
                  Total Hub Pending Delivery<br> Product Requisition
                </div>
              </div>
              <div class="flex-shrink-0 font-size-36 text-gray-4 pl-1">
                <router-link :to="{ name: 'requisitionHubPendingDeliveryList' }" tag="a">
                  <i class="fa fa-circle-o-notch fa-spin" style="color: #ffc107"/>
                </router-link>
              </div>
            </div>
            <div class="font-italic font-size-14 text-center border-top pt-3"></div>
          </div>
        </div>
      </div>
      <!-- Start Expense-->
      <div class="col-lg-4 col-md-12 custom-row-div" v-if="user.role.includes('software_admin') || user.role.includes('head_of_admin') || user.role.includes('store_in_charge') || user.role.includes('hq') || user.role.includes('top_management')">
        <div class="card">
          <div class="py-3 px-4">
            <div class="d-flex flex-wrap-reverse align-items-center pb-3">
              <div class="mr-auto">
                <div class="text-uppercase font-weight-bold font-size-24 text-dark">
                  <a-spin :spinning="spinning" :delay="delayTime">
                    ৳ {{ currencyConverter(total_hub_requested_transactions) }}
                  </a-spin>
                </div>
                <div class="font-size-18">
                  Total Hub Expense Amount<br>
                </div>
              </div>
              <div class="flex-shrink-0 font-size-36 text-gray-4 pl-1">
                <i class="fe fe-bar-chart" style="color: #A52A2A"  />
              </div>
            </div>
            <div class="font-italic font-size-14 text-center border-top pt-3"></div>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-12 custom-row-div" v-if="user.role.includes('software_admin') || user.role.includes('head_of_admin') || user.role.includes('store_in_charge') || user.role.includes('hq') || user.role.includes('top_management')">
        <div class="card">
          <div class="py-3 px-4">
            <div class="d-flex flex-wrap-reverse align-items-center pb-3">
              <div class="mr-auto">
                <div class="text-uppercase font-weight-bold font-size-24 text-dark">
                  <router-link :to="{ name: 'adminExpensePreviousList' }" tag="a">
                    <a-spin :spinning="spinning" :delay="delayTime">
                      {{ total_hub_approved_expense_transaction }}
                    </a-spin>
                  </router-link>
                </div>
                <div class="font-size-18">
                  Total Hub Approved Expense<br>
                </div>
              </div>
              <div class="flex-shrink-0 font-size-36 text-gray-4 pl-1">
                <router-link :to="{ name: 'adminExpensePreviousList' }" tag="a">
                  <i class="fa fa-check" style="color: #28a745"/>
                </router-link>
              </div>
            </div>
            <div class="font-italic font-size-14 text-center border-top pt-3"></div>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-12 custom-row-div" v-if="user.role.includes('software_admin') || user.role.includes('head_of_admin') || user.role.includes('store_in_charge') || user.role.includes('hq') || user.role.includes('top_management')">
        <div class="card">
          <div class="py-3 px-4">
            <div class="d-flex flex-wrap-reverse align-items-center pb-3">
              <div class="mr-auto">
                <div class="text-uppercase font-weight-bold font-size-24 text-dark">
                  <router-link :to="{ name: 'adminExpensePendingList' }" tag="a">
                    <a-spin :spinning="spinning" :delay="delayTime">
                      {{ total_hub_pending_expense_transaction }}
                    </a-spin>
                  </router-link>
                </div>
                <div class="font-size-18">
                  Total Hub Pending Expense<br>
                </div>
              </div>
              <div class="flex-shrink-0 font-size-36 text-gray-4 pl-1">
                <router-link :to="{ name: 'adminExpensePendingList' }" tag="a">
                  <i class="fa fa-circle-o-notch fa-spin" style="color: #ffc107"/>
                </router-link>
              </div>
            </div>
            <div class="font-italic font-size-14 text-center border-top pt-3"></div>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-12 custom-row-div" v-if="user.role.includes('software_admin') || user.role.includes('head_of_admin') || user.role.includes('store_in_charge') || user.role.includes('hq') || user.role.includes('top_management')">
        <div class="card">
          <div class="py-3 px-4">
            <div class="d-flex flex-wrap-reverse align-items-center pb-3">
              <div class="mr-auto">
                <div class="text-uppercase font-weight-bold font-size-24 text-dark">
                  <router-link :to="{ name: 'adminExpenseRejectedList' }" tag="a">
                    <a-spin :spinning="spinning" :delay="delayTime">
                      {{ total_hub_reject_expense_transaction }}
                    </a-spin>
                  </router-link>
                </div>
                <div class="font-size-18">
                  Total Hub Reject Expense<br>
                </div>
              </div>
              <div class="flex-shrink-0 font-size-36 text-gray-4 pl-1">
                <router-link :to="{ name: 'adminExpenseRejectedList' }" tag="a">
                  <i class="fa fa-close" style="color: #dc3545"/>
                </router-link>
              </div>
            </div>
            <div class="font-italic font-size-14 text-center border-top pt-3"></div>
          </div>
        </div>
      </div>
      <!-- End Expense-->
      <!--End Requisition Section-->
      <div class="col-lg-4 col-md-12 custom-row-div" v-if="user.role.includes('software_admin') || user.role.includes('head_of_admin') || user.role.includes('store_in_charge') || user.role.includes('hq') || user.role.includes('top_management')">
        <div class="card">
          <div class="py-3 px-4">
            <div class="d-flex flex-wrap-reverse align-items-center pb-3">
              <div class="mr-auto">
                <div class="text-uppercase font-weight-bold font-size-24 text-dark">
                  <a-spin :spinning="spinning" :delay="delayTime">
                    {{ total_user }}
                  </a-spin>
                </div>
                <div class="font-size-18">
                  Total Users <br>
                </div>
              </div>
              <div class="flex-shrink-0 font-size-36 text-gray-4 pl-1">
                <i class="fe fe-users" style="color: #007bff" />
              </div>
            </div>
            <div class="font-italic font-size-14 text-center border-top pt-3"></div>
          </div>
        </div>
      </div>
      <!--Hub in charge-->
      <div class="col-lg-4 col-md-12 custom-row-div" v-if="user.role.includes('hub_in_charge')">
        <div class="card">
          <div class="py-3 px-4">
            <div class="d-flex flex-wrap-reverse align-items-center pb-3">
              <div class="mr-auto">
                <div class="text-uppercase font-weight-bold font-size-24 text-dark">
                  <a-spin :spinning="spinning" :delay="delayTime">
                    {{ hub_consumption }}
                  </a-spin>
                </div>
                <div class="font-size-18">
                  Total Items Consumption
                </div>
              </div>
              <div class="flex-shrink-0 font-size-36 text-gray-4 pl-1">
                <i class="fe fe-check" style="color: #28a745" />
              </div>
            </div>
            <div class="font-italic font-size-14 text-center border-top pt-3"></div>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-12 custom-row-div" v-if="user.role.includes('hub_in_charge')">
        <div class="card">
          <div class="py-3 px-4">
            <div class="d-flex flex-wrap-reverse align-items-center pb-3">
              <div class="mr-auto">
                <div class="text-uppercase font-weight-bold font-size-24 text-dark">
                  <a-spin :spinning="spinning" :delay="delayTime">
                    ৳ {{ currencyConverter(hub_total_current_stock_balance) }}
                  </a-spin>
                </div>
                <div class="font-size-18">
                  Total Current Stock Value
                </div>
              </div>
              <div class="flex-shrink-0 font-size-36 text-gray-4 pl-1">
                <i class="fa fa-balance-scale" style="color: #808000" />
              </div>
            </div>
            <div class="font-italic font-size-14 text-center border-top pt-3"></div>
          </div>
        </div>
      </div>
      <!--Start Admin Scenario-->
      <div class="col-lg-4 col-md-12 custom-row-div" v-if="user.role.includes('software_admin') || user.role.includes('head_of_admin') || user.role.includes('admin_scenario_admin')">
        <div class="card">
          <div class="py-3 px-4">
            <div class="d-flex flex-wrap-reverse align-items-center pb-3">
              <div class="mr-auto">
                <div class="text-uppercase font-weight-bold font-size-24 text-dark">
                  <a-spin :spinning="spinning" :delay="delayTime">
                    {{ total_admin_scenario_issue_count }}
                  </a-spin>
                </div>
                <div class="font-size-18">
                  Admin Scenario - Total Issue<br>
                </div>
              </div>
              <div class="flex-shrink-0 font-size-36 text-gray-4 pl-1">
                <i class="fa fa-close fa-spin" style="color: #dc3545"/>
              </div>
            </div>
            <div class="font-italic font-size-14 text-center border-top pt-3"></div>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-12 custom-row-div" v-if="user.role.includes('software_admin') || user.role.includes('head_of_admin') || user.role.includes('admin_scenario_admin')">
        <div class="card">
          <div class="py-3 px-4">
            <div class="d-flex flex-wrap-reverse align-items-center pb-3">
              <div class="mr-auto">
                <div class="text-uppercase font-weight-bold font-size-24 text-dark">
                  <a-spin :spinning="spinning" :delay="delayTime">
                    {{ total_admin_scenario_pending_count }}
                  </a-spin>
                </div>
                <div class="font-size-18">
                  Admin Scenario - Total Pending Issue<br>
                </div>
              </div>
              <div class="flex-shrink-0 font-size-36 text-gray-4 pl-1">
                <i class="fa fa-circle-o-notch fa-spin" style="color: #ffc107"/>
              </div>
            </div>
            <div class="font-italic font-size-14 text-center border-top pt-3"></div>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-12 custom-row-div" v-if="user.role.includes('software_admin') || user.role.includes('head_of_admin') || user.role.includes('admin_scenario_admin')">
        <div class="card">
          <div class="py-3 px-4">
            <div class="d-flex flex-wrap-reverse align-items-center pb-3">
              <div class="mr-auto">
                <div class="text-uppercase font-weight-bold font-size-24 text-dark">
                  <a-spin :spinning="spinning" :delay="delayTime">
                    {{ total_admin_scenario_inprogress_count }}
                  </a-spin>
                </div>
                <div class="font-size-18">
                  Admin Scenario - Total Inprogress Issue<br>
                </div>
              </div>
              <div class="flex-shrink-0 font-size-36 text-gray-4 pl-1">
                <i class="fa fa-close fa-spin" style="color: #dc3545"/>
              </div>
            </div>
            <div class="font-italic font-size-14 text-center border-top pt-3"></div>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-12 custom-row-div" v-if="user.role.includes('software_admin') || user.role.includes('head_of_admin') || user.role.includes('admin_scenario_admin')">
        <div class="card">
          <div class="py-3 px-4">
            <div class="d-flex flex-wrap-reverse align-items-center pb-3">
              <div class="mr-auto">
                <div class="text-uppercase font-weight-bold font-size-24 text-dark">
                  <a-spin :spinning="spinning" :delay="delayTime">
                    {{ total_admin_scenario_solved_count }}
                  </a-spin>
                </div>
                <div class="font-size-18">
                  Admin Scenario - Total Solved Issue<br>
                </div>
              </div>
              <div class="flex-shrink-0 font-size-36 text-gray-4 pl-1">
                <i class="fa fa-check" style="color: #28a745"/>
              </div>
            </div>
            <div class="font-italic font-size-14 text-center border-top pt-3"></div>
          </div>
        </div>
      </div>
      <!--End Admin Scenario-->
    </div>
    <div class="row">
      <div class="col-lg-4 col-md-12 custom-row-div" v-if="user.role.includes('head_of_admin') || user.role.includes('store_in_charge')">
        <div class="card">
          <div class="card-header">
            <div class="cui__utils__heading mb-0"><strong>Product Stock Low</strong></div>
            <div class="text-muted">Product name with quantity </div>
          </div>
          <a-spin :spinning="spinning" :delay="delayTime">
            <div class="card-body" style="overflow: scroll; max-height: 300px;">
              <table class="table" style="width: 100%">
                <tbody class="ant-table-tbody">
                <tr v-for="(warehouse_product, index) in warehouse_products" :key="index">
                  <td>{{ index + 1 }}</td>
                  <td>{{ warehouse_product.name }}</td>
                  <td>{{ warehouse_product.available_quantity }}</td>
                </tr>
                </tbody>
              </table>
              <div v-if="warehouse_products.length === 0" class="font-italic font-size-14 text-center">No Product Found</div>
            </div>
          </a-spin>
        </div>
      </div>
      <div class="col-lg-4 col-md-12 custom-row-div" v-if="user.role.includes('hub_in_charge')">
        <div class="card">
          <div class="card-header">
            <div class="cui__utils__heading mb-0"><strong>Product Stock Low</strong></div>
            <div class="text-muted">Product name with quantity </div>
          </div>
          <a-spin :spinning="spinning" :delay="delayTime">
            <div class="card-body" style="overflow: scroll; max-height: 300px;">
              <table class="table" style="width: 100%">
                <tbody class="ant-table-tbody">
                <tr v-for="(hub_product, index) in hub_products" :key="index">
                  <td>{{ index + 1 }}</td>
                  <td>{{ hub_product.name }}</td>
                  <td>{{ hub_product.stock_amount }}</td>
                </tr>
                </tbody>
              </table>
              <div v-if="hub_products.length === 0" class="font-italic font-size-14 text-center">No Product Found</div>
            </div>
          </a-spin>
        </div>
      </div>
    </div>
    <div class="row" v-if="user.role.includes('software_admin') || user.role.includes('head_of_admin') || user.role.includes('store_in_charge') || user.role.includes('hq') || user.role.includes('top_management')">
      <div class="col-lg-6 col-md-12 custom-row-div">
        <div class="card">
          <div class="card-header">
            <div class="cui__utils__heading mb-0"><strong>Expense</strong></div>
            <div class="text-muted">Chart of Account(COA) </div>
          </div>
          <a-spin :spinning="spinning" :delay="delayTime">
            <div class="card-body">
              <div class="mb-5">
                <pie-chart :height="400" />
              </div>
            </div>
          </a-spin>
        </div>
      </div>
      <div class="col-lg-6 col-md-12 custom-row-div">
        <div class="card">
          <div class="card-header">
            <div class="cui__utils__heading mb-0"><strong>Expense - Month</strong></div>
            <div class="text-muted">Chart of Account(COA) </div>
          </div>
          <a-spin :spinning="spinning" :delay="delayTime">
            <div class="card-body">
              <div class="mb-5">
                <bar-chart :height="400" />
              </div>
            </div>
          </a-spin>
        </div>
      </div>
    </div>
    <div class="row" v-if="user.role.includes('admin_scenario_admin')">
      <div class="col-lg-6 col-md-12 custom-row-div">
        <div class="card">
          <div class="card-header">
            <div class="cui__utils__heading mb-0"><strong>Admin Scenario - Monthly Rating</strong></div>
          </div>
          <a-spin :spinning="spinning" :delay="delayTime">
            <div class="card-body">
              <div class="mb-5">
                <admin-scenario-rating-bar-chart :height="400" />
              </div>
            </div>
          </a-spin>
        </div>
      </div>
    </div>
    <div class="row" v-if="user.role.includes('software_admin') || user.role.includes('head_of_admin') || user.role.includes('store_in_charge') || user.role.includes('hq') || user.role.includes('top_management')">
      <div class="col-lg-6 col-md-12 custom-row-div">
        <div class="card">
          <div class="card-header">
            <div class="cui__utils__heading mb-0"><strong>Monthly Expense Increment(%)</strong></div>
          </div>
          <a-spin :spinning="spinning" :delay="delayTime">
            <div class="card-body">
              <table class="table " style="width: 100%">
                <thead class="ant-table-tbody">
                <tr>
                  <th v-for="(monthly_total_requested_transaction, key, index) in monthly_total_requested_transactions" :key="index">
                    {{ key }}
                  </th>
                  <th>Percentage(%)</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                  <td v-for="(monthly_total_requested_transaction, key, index) in monthly_total_requested_transactions" :key="index">
                    {{ currencyConverter(monthly_total_requested_transaction) }}
                  </td>
                  <td>
                         <span v-if="monthly_expense_difference >= 0">
                           <span v-if="monthly_expense_difference == 0">
                             {{ monthly_expense_difference }}
                           </span>
                           <span v-else>
                             <span class="text-danger">
                               <i class="fa fa-arrow-up"></i>
                               {{ monthly_expense_difference }}
                             </span>
                           </span>
                         </span>
                    <span v-else>
                           <span class="text-success">
                               <i class="fa fa-arrow-down"></i>
                               {{ monthly_expense_difference }}
                             </span>
                         </span>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </a-spin>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import apiClient from '@/services/axios'
import { mapGetters } from 'vuex'
import pieChart from '@/views/dashboard/pieChart'
import barChart from '@/views/dashboard/barChart'
import adminScenarioRatingBarChart from '@/views/dashboard/adminScenarioRatingBarChart'

export default {
  name: 'VbDashboard',
  components: { pieChart, barChart, adminScenarioRatingBarChart },
  data() {
    return {
      spinning: false,
      delayTime: 500,
      total_product: 0,
      total_purchase_request_count: 0,
      total_product_value: 0,
      total_user: 0,
      hub_consumption: 0,
      hub_total_current_stock_balance: 0,
      total_product_requisition: 0,
      total_admin_approved_product_requisition: 0,
      total_admin_pending_product_requisition: 0,
      total_warehouse_approved_product_requisition: 0,
      total_warehouse_pending_product_requisition: 0,
      total_warehouse_reject_product_requisition: 0,
      total_delivery_product_requisition: 0,
      total_hub_accepted_product_requisition: 0,
      total_hub_pending_product_requisition: 0,
      total_tejgaon_pending_product_requisition: 0,
      total_warehouse_transfer_pending_product_requisition: 0,
      total_hub_approved_expense_transaction: 0,
      total_hub_pending_expense_transaction: 0,
      total_hub_reject_expense_transaction: 0,
      total_current_stock_value: 0,
      total_hub_requested_transactions: 0,
      unreadNotifications: {},
      warehouse_products: {},
      hub_products: {},
      hub_notices: {},
      timer: '',
      monthly_total_requested_transactions: '',
      monthly_expense_difference: '',
      total_admin_scenario_issue_count: 0,
      total_admin_scenario_pending_count: 0,
      total_admin_scenario_solved_count: 0,
      total_admin_scenario_inprogress_count: 0,
    }
  },
  created () {
    this.timer = setInterval(this.dashboardCount, 900000)
    window.Echo.channel('NewPurchaseRequestChannel').listen('.NewPurchaseRequestEvent', (e) => {
      if (e.purchase_request) {
        this.total_purchase_request_count += 1
        this.total_product_value = parseFloat(this.total_product_value) + parseFloat(e.purchase_request.grand_total)
      }
    })
  },
  mounted() {
    this.dashboardCount()
  },
  computed: {
    ...mapGetters('user', ['user']),
  },
  methods: {
    dashboardCount() {
      this.spinning = true
      apiClient.get('api/dashboard/info')
        .then(response => {
          if (!response.data.error) {
            this.spinning = false
            const data = response.data
            this.total_product = data.total_product
            this.total_purchase_request_count = data.total_purchase_request_count
            this.total_product_value = data.total_product_value
            this.total_user = data.total_user
            this.hub_consumption = data.hub_consumption
            this.hub_total_current_stock_balance = data.hub_total_current_stock_balance
            this.unreadNotifications = data.unreadNotifications
            this.warehouse_products = data.warehouse_products
            this.hub_products = data.hub_products
            this.hub_notices = data.hub_notices
            this.total_product_requisition = data.total_product_requisition
            this.total_admin_approved_product_requisition = data.total_admin_approved_product_requisition
            this.total_admin_pending_product_requisition = data.total_admin_pending_product_requisition
            this.total_warehouse_approved_product_requisition = data.total_warehouse_approved_product_requisition
            this.total_warehouse_pending_product_requisition = data.total_warehouse_pending_product_requisition
            this.total_warehouse_reject_product_requisition = data.total_warehouse_reject_product_requisition
            this.total_delivery_product_requisition = data.total_delivery_product_requisition
            this.total_hub_accepted_product_requisition = data.total_hub_accepted_product_requisition
            this.total_hub_pending_product_requisition = data.total_hub_pending_product_requisition
            this.total_tejgaon_pending_product_requisition = data.total_tejgaon_pending_product_requisition
            this.total_warehouse_transfer_pending_product_requisition = data.total_warehouse_transfer_pending_product_requisition
            this.total_hub_approved_expense_transaction = data.total_hub_approved_expense_transaction
            this.total_hub_pending_expense_transaction = data.total_hub_pending_expense_transaction
            this.total_hub_reject_expense_transaction = data.total_hub_reject_expense_transaction
            this.total_current_stock_value = data.total_current_stock_value
            this.total_hub_requested_transactions = data.total_hub_requested_transactions
            this.monthly_total_requested_transactions = data.monthly_total_requested_transactions
            this.monthly_expense_difference = data.monthly_expense_difference
            this.total_admin_scenario_issue_count = data.total_admin_scenario_issue_count
            this.total_admin_scenario_pending_count = data.total_admin_scenario_pending_count
            this.total_admin_scenario_solved_count = data.total_admin_scenario_solved_count
            this.total_admin_scenario_inprogress_count = data.total_admin_scenario_inprogress_count
          }
          this.spinning = false
        })
        .catch(error => {
          console.log(error)
        })
    },
    markAsRead(noticeId) {
      apiClient.post('api/notice-mark-as-read', { noticeId: noticeId })
        .then(response => {
          this.dashboardCount()
        })
        .catch(error => {
          console.log(error)
        })
    },
    currencyConverter(x) {
      return this.$localCurrency(x)
    },
    cancelAutoUpdate () {
      clearInterval(this.timer)
    },
    beforeDestroy () {
      this.cancelAutoUpdate()
    },
  },
}
</script>
<style scoped>
.spin-content {
  border: 1px solid #91d5ff;
  background-color: #e6f7ff;
  padding: 30px;
}

.alert .close {
  color: #111010 !important;
}

.alert {
  color: #111 !important;
  animation: blink 2s linear infinite;
}

@keyframes blink {
  0% {
    opacity: 0;
  }
  50% {
    opacity: .5;
  }
  100% {
    opacity: 1;
  }
}

.custom-row-div{
  padding-right: 0px !important;
  padding-left: 8px !important;
}
</style>
